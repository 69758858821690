export default {
  actualsNotificationTitle: 'Heb je net een training gedaan?',
  actualsNotificationText:
    'Zijn de geplande waardes anders dan de daadwerkelijke training? Pas nu je actuals aan.',
  actualsInfoText:
    'In dit scherm kan je de actuals aanpassen van je training. Voor nu kan je alleen de daadwerkelijke tijd en wattage aanpassen. Je gewijzigde energiebehoefte zal in de planner zichtbaar zijn.',
  activityLevel: {
    question: 'Wanneer je niet sport of werkt, in welke mate ben je dan nog lichamelijk actief?',
    explanation:
      'Zit je bijvoorbeeld veel thuis, kijk je televisie en doe je tussendoor boodschappen of laat je de hond uit? Dan ben je gemiddeld actief. Erg actief ben je wanneer je urenlang achter de bar in de sportkantine staat. En ben je in je vrije tijd urenlang aan het klussen of tuinieren? Dan ben je extreem actief.',
  },
  addFirstTraining: 'Klaar om je eerste training toe te voegen?',
  blogs:
    'Wil jij voldoende energie hebben rondom het sporten, je weerstand op orde krijgen en goed herstellen? Wij inspireren en coachen je.',
  customRecipeIntroductionUSPs: [
    'Voer je eigen recept en de ingrediënten in',
    'Speel met de verhouding van de ingrediënten om je recept beter passend te maken voor jouw voedingsplan',
    'Plan je recept in. Wij slaan het voor je op, zodat je het recept op andere dagen opnieuw kunt inplannen.',
  ],
  dailyEnergyNeed: {
    explanation:
      'Mooi, we hebben je profiel op een rustdag bepaald! We weten wat je gemiddelde energiebehoefte is en kunnen je een richting geven hoeveel koolhydraten, eiwitten en vetten je nodig hebt.',
    edit: 'Je kan deze gegevens controleren en later altijd wijzigen in je profiel.',
  },
  doesNotMatchMealPlan:
    'Let op: de macronutriëntenverhouding van je recept past niet binnen je voedingsplan. {editRecipe} voordat je hem inplant.',
  exactMeasurement: [
    {
      text: "Wanneer Wout van Aert thuis kookt met The Athlete's FoodCoach en 0,7 courgette in zijn gerecht moet doen, kiest hij er vaak voor de hele courgette te gebruiken. Strikter is de renner van Team Visma | Lease a Bike wanneer het gaat om de hoeveelheid kip of pasta in zijn maaltijd. ‘Per ingrediënt kijk ik of het slim is om meer te pakken of niet’, zegt hij.",
    },
    {
      text: `Groente kun je in principe naar boven afronden, voegt Karin Lambrechtse als performance chef en sportdiëtist van Team Visma | Lease a Bike daaraan toe. "In groente zitten nauwelijks macronutriënten. Hierdoor doen ze weinig met je calorie-inname. Anderzijds geeft groente een boost aan je vitamines en mineralen en bevat vezels die belangrijk zijn voor je darmen. Maar onthoud dat een hogere inname van groente niet ten koste mag gaan van je geadviseerde macronutriënteninname."`,
    },
    {
      text: "Lambrechtse adviseert nauwkeurig te zijn met producten, zoals kip en pasta, die meer macronutriënten, en dus energie, bevatten. Al benadrukt ze dat een beetje speling geen kwaad kan. ‘Als je iets hoger of lager uitkomt met je energie-inname, is dat niet meteen heel erg, zolang het maar niet elke dag 100 kilocalorieën te veel of te weinig is. Besef dat The Athlete's FoodCoach jou het ideale voedingsplan voorschotelt. Blijf dus zo dicht mogelijk in de buurt van de adviezen.",
    },
    {
      text: "Dat doet ook Van Aert. 'The Athlete's FoodCoach maakt gezond en gevarieerd eten leuker en makkelijk omdat je nieuwe inspiratie opdoet en inzicht krijgt in wat je lichaam per eetmoment nodig heeft.’",
    },
  ],
  ftpExplanationIntro:
    'De meest betrouwbare manier om je FTP-waarde te meten, is een inspanningstest doen bij een inspanningsfysioloog of sportarts. Een alternatieve methode is het zelf meten. Smart hometrainers met vermogensmeting bieden vaak standaard 20 minuten FTP-testen aan of werken op standaard testen van software programma’s als Zwift.',
  ftpExplanation: [
    {
      text: 'Je kunt de 20 minuten test ook buiten uitvoeren, mits je fiets beschikt over een vermogensmeter. Probeer deze test onder gunstige omstandigheden te doen: op een vlak parcours (of één lange gelijkmatige klim van max 6%) en het liefst zonder bochten en zonder al te veel verkeer. Na een goede warming-up fiets je 20 minuten zo hard mogelijk in een constant tempo. Van het gemiddelde vermogen dat je in die 20 minuten trapt, trek je 5% af om je FTP-waarde in te schatten.',
    },
    {
      text: "Met bovenstaande testen kun je je FTP-waarde vrij nauwkeurig meten. Houd er rekening mee dat de geschatte FTP-waarde van The Athlete's FoodCoach een ruime of onjuiste schatting kan zijn. Hierdoor kan de energieberekening onjuist zijn.",
    },

    {
      text: "Ook kun je je FTP-waarde terugzoeken via activity trackers of verbonden apps. Hiervoor geldt hetzelfde als de geschatte FTP-waarde van The Athlete's FoodCoach: deze is minder accuraat dan een inspanningstest bij een inspanningsfysioloog of sportarts of wanneer je de test zelf uitvoert.",
    },
  ],
  ftpExplanationEstimation:
    'Op basis van je gegevens hebben we een eerste, voorzichtige schatting van je FTP-waarde gemaakt.',
  ftpExplanationQuestion: 'Weet jij je FTP-waarde? Dan kun je die hierboven zelf aanpassen.',
  ftpValue: {
    explanation:
      'Net als bij de renners van Team Visma | Lease a Bike bepalen we aan de hand van je FTP-waarde hoe intensief een inspanning voor jou is. Weet jij jouw FTP-waarde niet? Dan helpen we je. We hebben een eerste, voorzichtige inschatting van je FTP-waarde gemaakt.',
    edit: 'Wanneer je FTP-waarde verandert, kun je die eenvoudig aanpassen in je profiel.',
  },
  trainingManagedBy: {
    join: 'Deze training wordt beheerd door JOIN. Als je de trainingsduur, het type of de intensiteit wilt wijzigen, open dan de JOIN-app.',
    intervals:
      'Deze training wordt beheerd door Intervals.icu. Als je de trainingsduur, het type of de intensiteit wilt wijzigen, open dan de Intervals.icu app.',
    trainingpeaks:
      'Deze training wordt beheerd door TrainingPeaks. Als je de trainingsduur, het type of de intensiteit wilt wijzigen, open dan de TrainingPeaks app.',
  },
  length:
    'Om je goed te kunnen coachen, berekenen we eerst wat je lichaam aan energie nodig heeft als je niet sport. Daarvoor hebben we een aantal gegevens van je nodig. Te beginnen met je lengte.',
  myGoal:
    'Gezonder eten? Pas hier je doel aan. Gezondere eetgewoontes voor meer energie: plan een weekmenu, maak eetwissels en ga bewuster om met voeding.',
  noMatchingIngredients:
    'Je kan zelf een ingrediënt toevoegen, met de bijbehorende voedingswaarden.',
  nutrientsInfo: [
    {
      title: 'Verantwoording energiebehoefte berekening',
      text: `Sporters hebben een significant hogere energiebehoefte in rust dan niet-sporters. Dit komt met name door de hogere spiermassa ten opzichte van niet sporters. Om deze reden wordt er gewerkt met de Ten Haaf-Weijs formule [1]. Deze ten Haaf formule bepaald jouw energiebehoefte in volledige rust (jouw BMR). Voor alle dingen die je doet in het dagelijks leven heb je meer energie nodig, dit kunnen de kleinste dingen zijn. Een Metabolic Equivalent of Task (MET-waarde) compenseert hiervoor[2]. De The Athlete's FoodCoach app berekent met behulp van vier MET-waardes die jij zelf invult (het aantal slaapuren, het type werk, het aantal werkuren en jouw activiteitenniveau buiten sport en werk om) jouw physical activity level (PAL-waarde). Door deze BMR en PAL waarde te vermenigvuldigen berekenen wij jouw energiebehoefte op een rustdag. Ga jij sporten? Dan corrigeren wij jouw energiegebruik hiervoor [2,3].`,
    },
    {
      title: 'Verantwoording macronutriënt berekening',
      text: `De verdeling van energie, koolhydraten, eiwitten en vetten is afhankelijk van het maaltijdmoment en het type training, moment van trainen, de duur en intensiteit van de training, maar ook van persoonlijke factoren zoals lengte, leeftijd en gewicht. The Athlete's FoodCoach helpt met de berekening van jouw energiebehoefte en bij de macronutriëntenverdeling per maaltijd volgens de methodieken die gebaseerd zijn op de sportvoedingsrichtlijnen en ook zijn toegepast bij topsporters, waaronder de renners van Team Visma | Lease a Bike.`,
    },
    {
      title: 'Referenties',
      text: `[1] Ten Haaf, T. & Weijs, P. J. M. Resting Energy Expenditure Prediction in Recreational Athletes of 18-35 Years: Confirmation of Cunningham Equation and an Improved Weight-Based Alternative. (2014) doi:10.1371/journal.pone.0108460. {0}`,
      links: [
        {
          text: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4183531/',
          url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4183531/',
        },
      ],
    },
    {
      text: `[2] Ainsworth, B. E. et al. 2011 Compendium of Physical Activities: A Second Update of Codes and MET Values. Med. Sci. Sport. Exerc 43, 1575–1581 (2011). {0}`,
      links: [
        {
          text: 'https://pubmed.ncbi.nlm.nih.gov/21681120/',
          url: 'https://pubmed.ncbi.nlm.nih.gov/21681120/',
        },
      ],
    },
    {
      text: `[3]. Margaria, R., Cerretelli, P., Aghemo, P. & Sassi, G. Energy cost of running. J. Appl. Physiol. 18, 367–370 (1963). {0}`,
      links: [
        {
          text: 'https://journals.physiology.org/doi/abs/10.1152/jappl.1963.18.2.367',
          url: 'https://journals.physiology.org/doi/abs/10.1152/jappl.1963.18.2.367',
        },
      ],
    },
  ],
  onActivityExplanation: [
    {
      text: 'Pijnlijke spieren, duizeligheid en een waas voor zijn ogen. Het zijn de verschijnselen wanneer Jonas Vingegaard een hongerklop heeft. ‘Je wilt wel, maar voelt je helemaal leeg. Je vraagt dingen van je lichaam, waar het de energie niet meer voor heeft’, zegt de renner van Team Visma | Lease a Bike.',
    },
    {
      text: 'Bij een hongerklop ben je volledig door je koolhydraatvoorraden heen: de favoriete brandstof van je lichaam om op hoge intensiteit in te spannen. Je lichaam moet noodgedwongen overschakelen op je vetverbranding. Een veel minder snelle brandstof, waardoor je niet meer op hetzelfde tempo kan blijven fietsen. ‘Je lichaam komt in een soort noodtoestand’, zegt Martijn Redegeld, sportdiëtist van Team Visma | Lease a Bike.',
    },
    {
      text: "The Athlete's FoodCoach helpt je bij het voorkomen van een hongerklop door ervoor te zorgen dat je met je maaltijden voor een training je koolhydraatvoorraden (in je lever en spieren) maximaal vult. Ook adviseert The Athlete's FoodCoach wat je tijdens je training aan koolhydraten moet nemen, zodat je op je fiets niet zonder koolhydraten komt te staan.",
    },
    {
      text: "Ga je maximaal twee uur intensief trainen? Dan raad The Athlete's FoodCoach je aan om minimaal 20 gram koolhydraten per uur te pakken. Bij inspanningen langer dan twee uur is het advies minimaal 40 gram per uur. Redegeld: ‘Door je koolhydraten voortdurend aan te vullen, voorkom je dat de bodem van je brandstoftank bereikt wordt en je een hongerklop ervaart.’",
    },
    {
      text: "Dumoulin: ‘The Athlete's FoodCoach geeft mij inzicht in hoeveel koolhydraten ik moet eten voor en tijdens mijn training. Daar houd ik mij aan vast. Voeding speelt een steeds grotere rol om goed te presteren. Voor mij staat trainen nog altijd op één, maar voeding komt daar al snel achteraan. Daarin maakt The Athlete's FoodCoach het verschil.’",
    },
  ],
  planMealsEnabled:
    'Op basis van je training kunnen we nu bepalen wat, wanneer en hoeveel je moet eten. Hierop kun je je maaltijden inplannen.',
  schedule: {
    explanation: 'Je training heeft een grote invloed op wat, wanneer en hoeveel je moet eten.',
    selectTraining:
      'Ben je klaar om te beginnen? Selecteer de datum van je eerste training en voeg deze toe.',
  },
  searchIngredientsToAddToRecipe:
    'Begin met zoeken van ingrediënten en voeg ze toe aan je eigen recept.',
  sleepingHours: 'Hoeveel uur slaap je per nacht?',
  sport: {
    hello: 'Hallo! Leuk dat we je mogen coachen.',
    helloName: 'Hallo!',
    onlyCycling:
      'Helaas wordt momenteel alleen de wielrennen ondersteund. Andere sporten volgen snel.',
    sportSelectorInfo:
      "The Athlete's FoodCoach is samen met de wielerprofs van Team Visma | Lease a Bike ontwikkeld. Wielrennen en hardlopen zijn de eerste sporten die door The Athlete's FoodCoach worden ondersteund. Andere sporten volgen snel.",
  },
  trainingIntensity: 'Bovenstaand gemiddeld wattage kun je zelf scherper stellen.',
  weight: 'Ook je gewicht speelt een belangrijke rol.',
  workingHours: 'Hoeveel uur werk je per dag?',
  workType: {
    intro:
      'Daarnaast vergt de ene baan meer energie van je lichaam dan de andere baan. Daarom willen we graag weten wat voor werk je doet.',
    explanation:
      'Zit je veel achter je bureau? Dan heb je zittend werk. Een leerkracht verricht licht actief werk. Een postbode heeft gemiddeld actief werk en als stratenmaker heb je zwaar actief werk.',
  },
  changeParticipantsWarning:
    'Let op: door het aantal personen te wijzigen, veranderen de hoeveelheden voor ingrediënten, jouw portie als sporter is {portionPercentage} van deze maaltijd.',
  nutrientDirection: 'Ga in verhouding {direction} in {nutrient}',
};
