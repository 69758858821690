import { useAsyncQuery } from '@/modules/shared/utils/apollo.util';
import GET_USER_PROFILE_QUERY from '@/modules/shared/graphql/queries/getUserProfile.query.graphql';
import { computed, onBeforeMount, ref } from 'vue';
import { Capacitor } from '@capacitor/core';
import { SCOPE_ADVANCED_PRO, SCOPE_PRO } from '@/modules/shared/constants/scope.conts';

export const useProfile = (fetchPolicy = 'cache-first') => {
  const user = ref(null);

  const isLifetime = computed(() => user.value?.subscription?.lifetime ?? false);

  const isLoading = ref(false);
  const language = computed(() => (user.value ? user.value.preferredLanguage || 'en' : undefined));
  const isPremium = computed(() => user.value?.premium ?? false);
  const excludedMacros = computed(() => {
    if (user.value?.macroSettings) {
      const macroSettings = user.value?.macroSettings;

      return Object.keys(macroSettings).filter(key => !macroSettings[key]);
    }
    return ['fibers'];
  });
  const subscriptionLabel = computed(() => {
    if (!user.value) {
      return '';
    }

    if (isLifetime.value) {
      return 'profile.misc.free';
    }

    const subscriptionId = user.value?.subscription?.subscriptionId ?? '';

    if (subscriptionId.includes('test')) {
      return 'Test';
    }

    if (subscriptionId.includes('yearly')) {
      return 'profile.misc.yearly';
    }

    if (subscriptionId.includes('monthly')) {
      return 'profile.misc.monthly';
    }

    return '';
  });

  const nutritionViewType = computed(() => {
    if (user.value?.macroSettings === null) {
      return 'advanced';
    }
    if (Object.keys(SCOPE_PRO).every(key => user.value?.macroSettings?.[key] === SCOPE_PRO[key])) {
      return 'core';
    }
    if (
      Object.keys(SCOPE_ADVANCED_PRO).every(
        key => user.value?.macroSettings?.[key] === SCOPE_ADVANCED_PRO[key]
      )
    ) {
      return 'advanced';
    }

    return 'coach';
  });

  const hasFeature = feature => user.value?.features?.includes(feature) ?? false;
  const hasFlag = flag => user.value?.flags?.includes(flag) ?? false;

  const hasFeatureOrFlag = featureOrFlag => hasFeature(featureOrFlag) || hasFlag(featureOrFlag);

  const showScanner = computed(
    () => user.value?.markets?.length > 0 && Capacitor.isPluginAvailable('BarcodeScanner')
  );

  const fetch = async () => {
    isLoading.value = true;
    const { result } = await useAsyncQuery(GET_USER_PROFILE_QUERY, null, 0, fetchPolicy);
    user.value = result.value?.getUserProfile?.user;
    isLoading.value = false;
  };
  onBeforeMount(async () => fetch());

  return {
    hasFeature,
    hasFeatureOrFlag,
    hasFlag,
    isPremium,
    isLifetime,
    language,
    isLoading,
    showScanner,
    subscriptionLabel,
    excludedMacros,
    user,
    nutritionViewType,
    fetch,
  };
};
