import { UnitOfMeasure } from '@blancofoodcoach/content-scaling';
import labels from './labels.en';
import languages from './languages.en';
import privacyStatement from './privacyStatement.en';
import termsAndConditions from './terms-and-conditions.en';

export default {
  tip: `Tip!`,
  buttons: {
    cancel: 'Cancel',
    close: 'Close',
    save: 'Save',
    viewRecipe: 'Recipe details',
    continue: 'Continue',
    confirm: 'Confirm',
  },
  counts: {
    [UnitOfMeasure.Grams]: '{n} g',
    kcal: '{n} kcal',
    kg: '{n} kg',
    minute: '{n} minutes | {n} minute | {n} minutes',
    [UnitOfMeasure.Milliliters]: '{n} ml',
    [UnitOfMeasure.Pieces]: '{n} pcs',
    [UnitOfMeasure.Pinches]: '{n} pinches | {n} pinch | {n} pinches',
    [UnitOfMeasure.Servings]: '{n} servings | {n} serving | {n} servings',
    [UnitOfMeasure.Slices]: '{n} slices | {n} slice | {n} slices',
    [UnitOfMeasure.Tablespoons]: '{n} tbs',
    [UnitOfMeasure.Teaspoons]: '{n} tsp',
    [UnitOfMeasure.Twigs]: '{n} twigs | {n} twig | {n} twigs',
    pct: '{n}%',
    bool: {
      yes: 'Yes',
      no: 'No',
    },
  },
  countLabels: {
    [UnitOfMeasure.Grams]: 'g',
    kcal: 'kcal',
    kg: 'kg',
    minute: 'minutes | minute | minutes',
    [UnitOfMeasure.Milliliters]: 'ml',
    [UnitOfMeasure.Pieces]: 'pcs',
    [UnitOfMeasure.Pinches]: 'pinches | pinch | pinches',
    [UnitOfMeasure.Servings]: 'servings | serving | servings',
    [UnitOfMeasure.Slices]: 'slice | slice | slices',
    [UnitOfMeasure.Tablespoons]: 'tbs',
    [UnitOfMeasure.Teaspoons]: 'tsp',
    [UnitOfMeasure.Twigs]: 'twigs | twig | twigs',
    undefined: '',
  },
  info: {
    macroDistributionExamples: {
      tip: `Tip!`,
      intro: `Below you can see examples of ingredients that contain carbohydrates, proteins or fats.`,
      carbs: `Are mainly in (whole)grain products such as bread, pasta, potatoes and rice. But also in legumes, fruit and to a lesser extent in vegetables.`,
      protein: `Animal proteins are mainly found in meat, fish, milk, cheese and eggs. Vegetable proteins are mainly found in bread, grain products, legumes, nuts and mushrooms.`,
      fat: `Fat is manly found in low-fat margarine, oil, meat, cheese and biscuits, snacks and sauces.`,
      fiber: `Fibres are mainly in wholegrain variants of products such as pasta, rice, bread and tortillas. Fruit, vegetables, nuts, legumes and potatoes also contain fibres.`,
    },
  },
  labels,
  links: {
    privacyStatement: 'Privacy Statement',
    termsAndConditions: "The Athlete's Terms and Conditions",
    viewGuidelines: 'View all guidelines',
  },
  misc: {
    down: 'down',
    up: 'up',
  },
  networkErrors: {
    default: 'Something went wrong, please try again or contact us',
    loginError: 'Unfortunately, the combination of e-mail address and password is incorrect.',
    accountExists:
      '<strong>This email address is already in use.</strong><br>Did you forget your password? <a href="/wachtwoord-vergeten">Reset your password here</a>.',
  },
  privacyStatement,
  termsAndConditions,
  notifications: {
    oops: 'Oops! Something went wrong.',
    oopsTryAgain: 'Oops! Something went wrong. Please try again later.',
    addFavoriteSucceeded: 'Add to favourites',
    removeFavoriteSucceeded: 'Delete from favourites',
    recipeRemoved: 'You recipe is deleted',
    ingredientSaved: 'You product is saved',
  },
  nutrients: {
    calories: 'Energy',
    carbs: 'Carbs',
    fat: 'Fats',
    fiber: 'Fibres',
    protein: 'Protein',
    saturatedFat: 'Saturated fats',
    saturatedFatPercent: 'Saturated fats percentage',
    sugar: 'Sugar',
    salt: 'Salt',
    transFat: 'Trans fats',
    addedSalt: 'Added salt',
    addedSugar: 'Added sugar',
    natrium: 'Natrium',
    addedTransfat: 'Added trans fats',
    fishPercent: 'Fish percentage',
    proteinEnperc: 'Energy percentage of protein',
  },
  languages,
  search: {
    noResults: "<strong>We can't find a recipes that fits your filter or search term…",
    noResult: 'No result',
    multipleResults: 'Multiple results',
  },
  basketCoachMark: {
    content: 'We made a list of the products you need.',
    confirmText: 'Okay',
  },
  serving: 'serving',
  persServing: 'Per serving',
  tags: {
    readMore: 'Read more',
  },
};
