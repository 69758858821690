<!-- eslint-disable vuejs-accessibility/no-autofocus -->
<script>
  import {
    JumButton,
    JumCollapsible,
    JumHeading,
    JumInputField,
    JumSelect,
    JumTable,
  } from '@blancofoodcoach/kompas';
  import { NUTRITION_TYPES } from '@/modules/shared/constants/nutrition.const';
  import GET_BASE_INGREDIENT_QUERY from '@/modules/planner/graphql/queries/getIngredient.graphql';
  import chatBubbleMixin from '@/modules/shared/mixins/chatBubbles.mixin';
  import { userProfileMixin } from '@/modules/shared/mixins/userProfile.mixin';
  import { useBasketCoachMark } from '@/modules/shared/composables/basketCoachMark';
  import { ScaledIngredientBuilder, UnitOfMeasure } from '@blancofoodcoach/content-scaling';
  import FcFormGroup from '@/modules/shared/components/form-group/FormGroup.vue';
  import { validationMixin } from '@/modules/shared/mixins/validation.mixin';
  import GET_RECIPE_QUERY from '@/modules/shared/graphql/queries/getRecipe.query.graphql';
  import UPDATE_USER_RECIPE_MUTATION from '@/modules/planner/graphql/mutations/updateUserRecipe.mutation.graphql';
  import { defineComponent } from 'vue';
  import { useMoment } from '@/modules/shared/composables/useMoment';

  export default defineComponent({
    name: 'AddProductToUserRecipeDetail',
    components: {
      JumCollapsible,
      JumHeading,
      FcFormGroup,
      JumInputField,
      JumSelect,
      JumTable,
      JumButton,
    },
    mixins: [chatBubbleMixin, userProfileMixin, validationMixin],
    apollo: {
      recipe: {
        query: GET_RECIPE_QUERY,
        variables() {
          return {
            id: this.$route.params.id,
          };
        },
        skip() {
          return !this.$route.params.id;
        },
        update({ getRecipe: { recipe } }) {
          return recipe;
        },
      },
      product: {
        query: GET_BASE_INGREDIENT_QUERY,
        fetchPolicy: 'no-cache',
        variables() {
          return {
            id: this.productId,
          };
        },
        update: ({ getIngredient: { ingredient } }) => ingredient,
      },
    },
    setup() {
      const { momentWithTrainingId, moment, trainingId } = useMoment();
      return {
        momentWithTrainingId,
        moment,
        trainingId,
      };
    },
    data: () => ({
      loading: false,
      isIngredientsBlockOpen: false,
      isComponentsBlockOpen: false,
      isPreparationBlockOpen: false,
      isNutrientsBlockOpen: false,
      NUTRITION_TYPES,
      chooseProductLoading: false,
      ingredientForm: {
        amount: 100,
        unitOfMeasure: 'Grams',
      },
      // apollo provided data
      deleted: false,
      recipe: null,
      product: null,
      mealTemplate: null,
    }),
    computed: {
      plannedProduct() {
        if (!this.recipe || !this.product) {
          return false;
        }

        return this.recipe.ingredients.some(({ ingredientId }) => ingredientId === this.product.id);
      },
      unitsOfMeasure: vm =>
        vm.product?.unitsOfMeasure.map(({ unitOfMeasure }) => ({
          value: unitOfMeasure,
          label: vm.$t(`shared.labels.unitsOfMeasureFull.${unitOfMeasure}`),
        })),
      productId: ({
        $route: {
          params: { ingredientId },
        },
      }) => ingredientId,
      unitsOfMeasureHeader: ({ $i18n, ingredientForm, product }) => {
        if (product && ingredientForm && ingredientForm.unitOfMeasure) {
          const translation = product.unitsOfMeasure.find(
            ({ unitOfMeasure }) => unitOfMeasure === ingredientForm.unitOfMeasure
          );
          return `${$i18n.t(`shared.labels.unitsOfMeasure.${ingredientForm.unitOfMeasure}`)}: ${
            translation.gramsTranslation
          } ${$i18n.t(`shared.labels.unitsOfMeasure.${UnitOfMeasure.Grams}`)}`;
        }
        return undefined;
      },
      scaledIngredient: ({ product, ingredientForm }) => {
        if (!product) {
          return undefined;
        }

        return ScaledIngredientBuilder.fromIngredient({
          ...product,
          unitOfMeasure: UnitOfMeasure.Grams,
        })
          .withAmount(parseFloat(ingredientForm.amount))
          .withUnitOfMeasureOverride(UnitOfMeasure[ingredientForm.unitOfMeasure])
          .build();
      },
      selectedDate: ({
        $route: {
          params: { date },
        },
      }) => date,
      recipeId: ({
        $route: {
          params: { ingredientId },
        },
      }) => ingredientId,
      nutrientsTable: ({ product, scaledIngredient }) => {
        if (!scaledIngredient || !product) {
          return null;
        }

        const { calories, carbs, protein, fat, fiber } = NUTRITION_TYPES;
        const nutrients = { calories, carbs, protein, fat, fiber };

        return Object.keys(nutrients).map(nutrient => ({
          label: nutrient,
          value: product.macros[nutrient],
          unitOfMeasure: scaledIngredient.macros[nutrient],
        }));
      },
    },
    methods: {
      async removeProduct() {
        const ingredients = this.recipe.ingredients.map(
          ({ amount, ingredientId, unitOfMeasure }) => ({
            amount,
            ingredientId,
            unitOfMeasure,
          })
        );

        ingredients.splice(
          this.recipe.ingredients.findIndex(
            ingredient => ingredient.ingredientId === this.product.id
          ),
          1
        );

        await this.$apollo.mutate({
          mutation: UPDATE_USER_RECIPE_MUTATION,
          variables: {
            id: this.recipe.id,
            ingredients,
          },
        });

        await this.notifyAndRedirect('planner.notifications.productRemoved');
      },
      async chooseProduct() {
        await useBasketCoachMark().enableCoachMark();
        const ingredient = this.recipe.ingredients.find(
          ({ ingredientId }) => ingredientId === this.product.id
        );

        if (ingredient) {
          ingredient.amount = parseFloat(this.ingredientForm.amount);
          ingredient.unitOfMeasure = this.ingredientForm.unitOfMeasure;
        } else {
          this.recipe.ingredients.push({
            amount: parseFloat(this.ingredientForm.amount),
            ingredientId: this.product.id,
            unitOfMeasure: this.ingredientForm.unitOfMeasure,
          });
        }

        const ingredients = this.recipe.ingredients.map(
          ({ amount, ingredientId, unitOfMeasure }) => ({
            amount,
            ingredientId,
            unitOfMeasure,
          })
        );

        await this.$apollo.mutate({
          mutation: UPDATE_USER_RECIPE_MUTATION,
          variables: {
            id: this.$route.params.id,
            ingredients,
          },
        });

        const notificationMessage = this.plannedProduct
          ? 'planner.notifications.productUpdatedForRecipe'
          : 'planner.notifications.productAdded';
        await this.notifyAndRedirect(notificationMessage);
      },
      async notifyAndRedirect(notificationMessage) {
        this.$notificationy({
          message: this.$t(notificationMessage, {
            product: this.product.name,
          }),
          icon: 'jum-check',
          iconColor: '#0fc647',
        });

        await this.$router.replace({
          name: 'planner-meals-user-edit',
          params: {
            date: this.selectedDate,
            moment: this.momentWithTrainingId,
            id: this.$route.params.id,
          },
        });
      },
      removeProductConfirm() {
        this.$modaly({
          title: this.$t('planner.buttons.deleteProductFromRecipe'),
          message: this.$t('planner.buttons.deleteWarning'),
          confirmText: this.$t('planner.buttons.confirmDeleteProductFromRecipe'),
          cancelText: this.$t('planner.buttons.cancelDelete'),
          onConfirm: async modal => {
            await this.removeProduct(true);
            await modal.close();
          },
          onCancel: modal => modal.close(),
        });
      },
      generateErrorMessage() {
        return {
          message: this.$t('shared.notifications.oops'),
          icon: 'jum-check',
          iconColor: '#e90000',
        };
      },
      async redirectToMealPlanner() {
        return this.$router.replace({
          name: 'planner-tune-your-meal',
          params: { date: this.selectedDate, moment: this.momentWithTrainingId },
        });
      },
      getNutrientValue(nutrient) {
        const { value, label } = nutrient;

        return label === 'fiber' ? parseFloat(value.toFixed(1)) : Math.round(value);
      },
      getUnitOfMeasureValue(nutrient) {
        const { unitOfMeasure, label } = nutrient;

        return label === 'fiber' ? parseFloat(unitOfMeasure.toFixed(1)) : Math.round(unitOfMeasure);
      },
    },
  });
</script>

<template>
  <fc-page class="recipe-detail">
    <template #top>
      <fc-nav-bar>
        {{ $t('planner.titles.product') }}
      </fc-nav-bar>
    </template>

    <div>
      <fc-page-content
        v-if="
          recipe && product && !$apollo.queries.recipe.loading && !$apollo.queries.product.loading
        "
        class="recipe-detail__content"
      >
        <div class="product-header">
          <jum-heading h1 styled="h3" class="margin-bottom-s">
            <strong>{{ product.name }}</strong>
          </jum-heading>
        </div>

        <validation-observer ref="authForm" v-slot="{ fields }">
          <div class="inputs">
            <validation-provider
              name="quantity"
              rules="general_number|general_required|general_greater_than_or_equals:0|general_decimal"
              slim
            >
              <fc-form-group>
                <jum-input-field
                  id="quantity"
                  v-model="ingredientForm.amount"
                  name="quantity"
                  data-test-id="input-amount"
                  autocomplete="off"
                  autofocus
                  type="number"
                  :valid="fieldIsValid(fields, 'quantity')"
                />
              </fc-form-group>
            </validation-provider>

            <validation-provider name="unitOfMeasure" rules="general_required" slim>
              <fc-form-group class="inputs__unit-of-measure">
                <jum-select
                  id="unit-of-measure"
                  v-model="ingredientForm.unitOfMeasure"
                  :options="unitsOfMeasure"
                  :disabled="unitsOfMeasure.length < 2"
                />
              </fc-form-group>
            </validation-provider>
          </div>
        </validation-observer>

        <jum-collapsible open class="meal-nutrient-details__collapsible">
          <template #title
            ><span class="meal-nutrient-details__title">{{
              $t(`planner.labels.nutritionalValues`)
            }}</span></template
          >

          <jum-table :striped="true" class="margin-bottom-m">
            <template #body>
              <tr>
                <td></td>
                <td class="table-value"><strong>100 g</strong></td>
                <td class="table-value">
                  <strong>{{ unitsOfMeasureHeader }}</strong>
                </td>
              </tr>
              <tr v-for="(nutrient, index) in nutrientsTable" :key="index">
                <td class="table-label">
                  {{ $t(`planner.labels.nutrients.${nutrient.label}`) }}
                </td>
                <td class="table-value">
                  <i18n :path="NUTRITION_TYPES[nutrient.label].unit" tag="div">
                    <template #n>{{ getNutrientValue(nutrient) }}</template>
                  </i18n>
                </td>
                <td class="table-value">
                  <i18n :path="NUTRITION_TYPES[nutrient.label].unit" tag="div">
                    <template #n>{{ getUnitOfMeasureValue(nutrient) }}</template>
                  </i18n>
                </td>
              </tr>
            </template>
          </jum-table>
        </jum-collapsible>
      </fc-page-content>
      <fc-content-spinner v-else />
    </div>

    <template #bottom>
      <fc-action-bar>
        <jum-button
          v-if="!plannedProduct"
          block
          secondary
          :in-progress="chooseProductLoading"
          data-test-id="in-plan-button"
          @click="chooseProduct"
        >
          {{ $t('planner.buttons.selectProduct') }}
        </jum-button>
        <jum-button
          v-if="plannedProduct"
          block
          secondary
          :in-progress="chooseProductLoading"
          data-test-id="in-plan-button"
          @click="chooseProduct"
        >
          {{ $t('planner.buttons.updateProduct') }}
        </jum-button>
        <jum-button
          v-if="plannedProduct"
          block
          :in-progress="chooseProductLoading"
          data-test-id="from-plan-button"
          secondary
          class="cta__delete"
          @click="removeProductConfirm"
        >
          {{ $t('planner.buttons.removeProduct') }}
        </jum-button>
      </fc-action-bar>
    </template>
  </fc-page>
</template>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .product-header {
    border-bottom: 1px solid $color-gray-lighten-80;
    margin-bottom: $spacing-m;
    padding: $spacing-m 0;
  }

  .inputs {
    display: flex;
    gap: 1rem;
    width: 100%;

    .fc-form-group {
      width: 100%;
    }

    .jum-select {
      width: 100%;
    }
  }

  .stat-item {
    align-items: center;
    color: $color-gray;
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }

  .table-label {
    font-weight: bold;
    text-align: left;
  }

  .table-value {
    padding-right: $spacing-m;
    text-align: right;
  }

  .cta__delete {
    border: 0 !important;
  }
</style>
