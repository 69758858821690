<script>
  import { App as CapApp } from '@capacitor/app';
  import { Network } from '@capacitor/network';
  import FcIcons from '@/modules/core/components/icons/Icons';
  import NiceModals from '@/modules/shared/components/nice-modals/NiceModals';
  import NiceNotifications from '@/modules/shared/components/nice-notifications/NiceNotifications';
  import NicePopovers from '@/modules/shared/components/nice-popovers/NicePopovers';
  import { appOpensMixin } from '@/modules/core/mixins/appOpens.mixin';
  import NETWORK_STATUS_MUTATION from '@/modules/shared/graphql/mutations/networkStatus.mutation.graphql';
  import NAVIGATION_INTENT_MUTATION from '@/modules/shared/graphql/mutations/navigationIntent.mutation.graphql';
  import { groceriesStore } from '@/modules/shared/providers/groceries';
  import { useProfile } from '@/modules/planner/composables/useProfile';
  import { watchOnce } from '@vueuse/core';
  import { useVueProxy } from '@/composables/useVueProxy';
  import { defineComponent } from 'vue';
  import { setLocale } from '@/modules/i18n/util/setLocale';
  import NiceTipMenus from '@/modules/shared/components/nice-tip-menu/NiceTipMenus.vue';
  import { PortalTarget } from 'portal-vue';
  import { closeBrowser } from '@/util/capacitor/closeBrowser';

  export default defineComponent({
    name: 'App',
    components: {
      PortalTarget,
      FcIcons,
      NiceModals,
      NiceNotifications,
      NicePopovers,
      NiceTipMenus,
    },
    mixins: [appOpensMixin],
    provide: {
      groceriesStore,
    },
    setup() {
      const { i18n } = useVueProxy();
      const { user } = useProfile();

      watchOnce(user, value => {
        if (value?.preferredLanguage) {
          setLocale(i18n, value.preferredLanguage);
        }
      });
    },
    data: () => ({
      redirectToLaunchUrl: true,
    }),
    computed: {
      currentRouteMeta: ({ $route: { matched } }) => (matched.length > 0 ? matched[0].meta : {}),
      showModals: vm => vm.currentRouteMeta.modals !== false,
      showNotifications: vm => vm.currentRouteMeta.notifications !== false,
      showPopovers: vm => vm.currentRouteMeta.popovers !== false,
      showTipMenu: vm => vm.currentRouteMeta.tipMenus !== false,
      cacheList: () =>
        'FcHome,AddIngredientsPage,PlaylistDetail,ChallengeDetailPage,ChallengeDetailNoPlaylistPage',
    },
    async created() {
      Network.addListener('networkStatusChange', ({ connected, connectionType }) => {
        this.$apollo.mutate({
          mutation: NETWORK_STATUS_MUTATION,
          variables: { connected, connectionType },
        });

        // reset navigationIntent to enable navigation
        if (connected) {
          this.$apollo.mutate({
            mutation: NAVIGATION_INTENT_MUTATION,
            variables: { navigationIntent: false },
          });
        }
      });
    },
    async updated() {
      if (this.redirectToLaunchUrl) {
        const handleUrl = async ({ url }) => {
          if (url.includes('profile/connect-devices')) {
            await closeBrowser();
          } else if (url.includes('profile/join')) {
            const domain = 'https://app.theathletesfoodcoach.com';
            const fullPath = url.replace(domain, '');
            await this.$router.push(fullPath);
          } else {
            await this.$router.push({ name: 'home' });
          }
        };
        CapApp.addListener('appUrlOpen', handleUrl);
        const data = await CapApp.getLaunchUrl();
        if (data?.url) {
          await handleUrl(data);
        }
        this.redirectToLaunchUrl = false;
      }
    },
  });
</script>

<template>
  <div id="app" class="foodcoach-app">
    <keep-alive :include="cacheList" :max="5">
      <router-view :key="$route.fullPath" class="foodcoach-app__view" />
    </keep-alive>

    <nice-modals v-if="showModals" class="foodcoach-app__modals" />
    <nice-notifications v-if="showNotifications" class="foodcoach-app__notifications" />
    <nice-popovers v-if="showPopovers" class="foodcoach-app_popovers" />
    <nice-tip-menus v-if="showTipMenu" class="foodcoach-app__tip-menu" />

    <portal-target name="overlay" />

    <fc-icons />
  </div>
</template>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  :global(html) {
    scroll-behavior: smooth;
  }

  .foodcoach-app {
    &__modals {
      z-index: $z-index-page-dialog;
    }

    &__notifications {
      z-index: $z-index-page-dialog;
    }

    &__popovers {
      z-index: $z-index-page-popover;
    }

    &__view {
      z-index: $z-index-router-view;
    }
  }
</style>
