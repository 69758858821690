import { UnitOfMeasure } from '@blancofoodcoach/content-scaling';
import labels from './labels.nl';
import languages from './languages.nl';
import privacyStatement from './privacyStatement.nl';
import termsAndConditions from './terms-and-conditions.nl';

export default {
  tip: `Tip!`,
  buttons: {
    cancel: 'Annuleren',
    close: 'Sluiten',
    save: 'Opslaan',
    viewRecipe: 'Recept bekijken',
    continue: 'Verder',
    confirm: 'Bevestigen',
  },
  counts: {
    [UnitOfMeasure.Grams]: '{n} g',
    kcal: '{n} kcal',
    kg: '{n} kg',
    minute: '{n} min',
    [UnitOfMeasure.Milliliters]: '{n} ml',
    [UnitOfMeasure.Pieces]: '{n} st',
    [UnitOfMeasure.Pinches]: '{n} snufjes | {n} snufje | {n} snufjes',
    [UnitOfMeasure.Slices]: '{n} sneetjes | {n} snee | {n} sneetjes',
    [UnitOfMeasure.Servings]: '{n} porties | {n} portie | {n} porties',
    [UnitOfMeasure.Tablespoons]: '{n} el',
    [UnitOfMeasure.Teaspoons]: '{n} tl',
    [UnitOfMeasure.Twigs]: '{n} takjes | {n} takje | {n} takjes',
    pct: '{n}%',
    bool: {
      yes: 'Ja',
      no: 'Nee',
    },
  },
  countLabels: {
    [UnitOfMeasure.Grams]: 'g',
    kcal: 'kcal',
    kg: 'kg',
    minute: 'minuten | minuut | minuten',
    [UnitOfMeasure.Milliliters]: 'ml',
    [UnitOfMeasure.Pieces]: 'st',
    [UnitOfMeasure.Pinches]: 'snuf',
    [UnitOfMeasure.Servings]: 'porties | portie | porties',
    [UnitOfMeasure.Slices]: 'snee',
    [UnitOfMeasure.Tablespoons]: 'el',
    [UnitOfMeasure.Teaspoons]: 'tl',
    [UnitOfMeasure.Twigs]: 'takjes | takje | takjes',
    undefined: '',
  },
  info: {
    macroDistributionExamples: {
      tip: `Tip!`,
      intro: `Hieronder zie je voorbeelden van ingrediënten waar koolhydraten, eiwitten of vetten in zitten.`,
      carbs: `Zitten vooral in (volkoren) graanproducten zoals brood, pasta, aardappelen en rijst. Maar ook in peulvruchten, fruit en in mindere mate in groente.`,
      protein: `Dierlijke eiwitten zitten vooral in vlees, vis, melk, kaas en eieren. Plantaardige eiwitten zitten vooral in brood, graanproducten, peulvruchten, noten en paddenstoelen.`,
      fat: `Vet zit vooral in halvarine, olie, vlees, kaas en in koek, snacks, noten en sauzen.`,
      fiber: `Vezels zitten vooral in volkoren variant van pasta, rijst, brood en tortilla's. Verder zitten vezels groente, fruit, peulvruchten en aardappelen.`,
    },
  },
  links: {
    privacyStatement: 'Privacy Statement',
    termsAndConditions: "The Athlete's FoodCoach Terms and Conditions",
    viewGuidelines: 'Bekijk de richtlijnen',
  },
  misc: {
    down: 'omlaag',
    up: 'omhoog',
  },
  networkErrors: {
    default: 'Er is iets mis gegaan, probeer het nog eens of neem contact met ons op',
    loginError: 'De combinatie van e-mailadres en wachtwoord klopt helaas niet.',
    accountExists:
      '<strong>Er bestaat al een account met dit e-mailadres.</strong><br>Ben je je wachtwoord vergeten? <a href="/wachtwoord-vergeten">Reset je wachtwoord hier</a>.',
  },
  privacyStatement,
  termsAndConditions,
  notifications: {
    oops: 'Oeps! Er is iets mis gegaan.',
    oopsTryAgain: 'Oeps! Er is iets mis gegaan. Probeer het later nog eens.',
    addFavoriteSucceeded: 'Aan je favorieten toegevoegd',
    removeFavoriteSucceeded: 'Verwijderd uit je favorieten',
    recipeRemoved: 'Je maaltijd is verwijderd',
    ingredientSaved: 'Je product is toegevoegd',
  },
  nutrients: {
    calories: 'Energie',
    carbs: 'Koolhydraten',
    fat: 'Vetten',
    fiber: 'Vezels',
    protein: 'Eiwitten',
    saturatedFat: 'Verzadigde vetten',
    saturatedFatPercent: 'Percentage verzadigde vetten',
    sugar: 'Suiker',
    salt: 'Zout',
    transFat: 'Transvetten',
    addedSalt: 'Toegevoegd zout',
    addedSugar: 'Toegevoegde suiker',
    natrium: 'Natrium',
    addedTransfat: 'Toegevoegde transvetten',
    fishPercent: 'Percentage vis',
    proteinEnperc: 'Energiepercentage eiwit',
  },
  labels,
  languages,
  search: {
    noResults:
      'We kunnen <strong>geen recept</strong> vinden dat past bij jouw filters of zoekopdracht…',
    noResult: 'Geen resultaat',
    multipleResults: 'Meerdere resultaten',
  },
  basketCoachMark: {
    content: 'We hebben de producten die je nodig hebt gemakkelijk op een rijtje gezet.',
    confirmText: 'Oké',
  },
  serving: 'portie',
  persServing: 'Per portie',
  tags: {
    readMore: 'Lees meer',
  },
};
