import { UnitOfMeasure } from '@blancofoodcoach/content-scaling';

export default {
  givenName: 'First name',
  middleName: 'Middle name',
  familyName: 'Family name',
  email: 'Email',
  optional: '(optional)',
  password: 'Password',
  female: 'Female',
  male: 'Male',
  dateFormat: 'dd-mm-yyyy',
  filters: {
    doneIn5Mins: 'Ready in 5 min',
    doneIn10Mins: 'Ready in 10 min',
    doneIn20Mins: 'Ready in 20 min',
    exact: 'Perfect fit',
    oat: 'Oatmeal',
    cottageCheese: 'Quark',
    bread: 'Bread',
    easy: 'Easy',
    favorites: 'Favorites',
    vegetarian: 'Vegetarian',
    vegetableRich: 'Vegetable rich',
    classics: 'Family recipes',
    bakingTogether: 'Baking recipes',
    vegan: 'Vegan',
    lowCarb: 'Low carbohydrate',
    pasta: 'Pasta',
    rice: 'Rice',
    lowCalories: 'Low calorie',
    schijfVanVijf: 'The Wheel of Five',
    season: 'Season',
    team: 'Team Recipes',
    potato: 'Patato',
    italian: 'Italian',
    dutch: 'Dutch',
    myRecipes: 'My recipes',
    mediterranean: 'Mediterranean',
    mexican: 'Mexican',
    oriental: 'Oriental',
    otherKitchen: 'Other',
    all: 'Everything',
    other: 'Other',
  },
  weightGoal: {
    maintain: 'Maintain',
    lose: 'Lose',
    gain: 'Gain',
  },
  gender: 'Sex',
  language: 'Language',
  unitsOfMeasure: {
    [UnitOfMeasure.Grams]: 'g',
    [UnitOfMeasure.Milliliters]: 'ml',
    [UnitOfMeasure.Pieces]: 'pcs',
    [UnitOfMeasure.Pinches]: 'pinch',
    [UnitOfMeasure.Servings]: 'srv',
    [UnitOfMeasure.Slices]: 'slice',
    [UnitOfMeasure.Tablespoons]: 'tbs',
    [UnitOfMeasure.Teaspoons]: 'tsp',
    [UnitOfMeasure.Twigs]: 'twig',
  },
  unitsOfMeasureFull: {
    [UnitOfMeasure.Grams]: 'grams',
    [UnitOfMeasure.Milliliters]: 'milliliters',
    [UnitOfMeasure.Pieces]: 'pieces',
    [UnitOfMeasure.Pinches]: 'pinches',
    [UnitOfMeasure.Servings]: 'servings',
    [UnitOfMeasure.Slices]: 'slices',
    [UnitOfMeasure.Tablespoons]: 'tablespoons',
    [UnitOfMeasure.Teaspoons]: 'teaspoons',
    [UnitOfMeasure.Twigs]: 'twigs',
  },
  trainingImportance: {
    true: 'Primary',
    false: 'Secondary',
  },
  trainingImportanceFull: {
    true: 'Primary training',
    false: 'Secondary training',
  },
  losingWeight: 'Losing weight',
  gainingWeight: 'Gaining weight',
};
