import { UnitOfMeasure } from '@blancofoodcoach/content-scaling';

export const product = {
  search: {
    whitelist: ['ei', 'ui'],
  },

  unitsOfMeasures: [
    UnitOfMeasure.Pieces,
    UnitOfMeasure.Servings,
    UnitOfMeasure.Pinches,
    UnitOfMeasure.Slices,
    UnitOfMeasure.Tablespoons,
    UnitOfMeasure.Teaspoons,
    UnitOfMeasure.Twigs,
  ],
};
